import app from 'flarum/app';
import { extend, override } from 'flarum/extend';
import Component from'flarum/Component';
import HeaderSecondary from "flarum/components/HeaderSecondary";
import LogInModal from'flarum/components/LogInModal';
import EditUserModal from'flarum/components/EditUserModal';
import SettingsPage from'flarum/components/SettingsPage';
import SessionDropdown from'flarum/components/SessionDropdown';

function getHotel(hotel) {
  switch(hotel) {
    case 'International': return '.com';
    case 'Finland': return '.fi';
    case 'Spain': return '.es';
    case 'Italy': return '.it';
    case 'Netherlands': return '.nl';
    case 'Germany': return '.de';
    case 'France': return '.fr';
    case 'Brazil': return '.com.br';
    case 'Turkey': return '.com.tr';
    default: return '.com';
  }
}

function formatUsername(elem) {
  elem.each(function() {
	var usernameElem = $(this);
	var username = usernameElem.text();
    var lastIndex = username.lastIndexOf('-');
    var habbo = username.substr(0, lastIndex);
	
    var hotelName = username.substr(lastIndex + 1);
    var hotelTld = getHotel(hotelName);

    if(habbo === undefined || hotelName === undefined || hotelTld === undefined) return;

    usernameElem.html(`<img src="https://www.habbo${hotelTld}/habbo-imaging/avatarimage?user=${habbo}&amp;direction=3&amp;head_direction=3&amp;action=&amp;gesture=nrm&amp;headonly=1&amp;size=s" alt="" class="habboFigure">${habbo}<img src="https://site.habboemotion.com/assets/images/hotels/${hotelName.toLowerCase()}.svg" alt="" class="habboHotel" />`);
	usernameElem.addClass('touched');
  });
}
	
app.initializers.add('testdev/flarum-hello-world', () => {
	/*extend(SignUpModal.prototype, 'fields', function (items) {
		items.add('p', <p>Your Text.</p>, -30);
	});
	
	extend(UserPage.prototype, 'init', function () {
		console.log('test');
		console.log(m.route.param('username'));
	});*/
	
	extend(HeaderSecondary.prototype, 'items', function (items) {
		items.remove("logIn");
	});
	
	override(LogInModal.prototype, 'content', function () {
		return [<div className="Modal-body">Login to use this feature.</div>];
	});
	
	extend(EditUserModal.prototype, 'fields', function (items) {
		items.remove('username');
		items.remove('password');
	});
	
	extend(SettingsPage.prototype, 'accountItems', function (items) {
		items.remove('changePassword');
		items.remove('changeEmail');
		
		items.add('info', <p>Visit the main site to change your email.</p>);
	});
	
	extend(SessionDropdown.prototype, 'items', function (items) {
		items.remove('separator');
		items.remove('logOut');
	});
	
	extend(Component.prototype, 'oncreate', function (node) {
		formatUsername($('.username, .PostMention').not('.touched'));
	});
});